.md-tabs {
    position: relative;
    z-index: 1;
    padding: .7rem;
    margin-right: 0rem;
    margin-bottom: -20px;
    margin-left: 0rem;
    background-color: #2bbbad;
    border: 0;
    border-radius: .25rem;
}

#cardProfile {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

#cardProfile {
    font-weight: 400;
    border: 0;
    box-shadow: none;
}

.tabs-black {
    background-color: rgb(0, 0, 0, 0.8) !important;
}

.md-tabs .nav-link.active, .md-tabs .nav-item.open .nav-link {
    color: #fff;
    border-radius: .25rem;
    transition: all 1s;
    background-color: transparent;
}

.font-weight-bolder {
    font-weight: 400 !important;
}

#profileButtons{
    justify-content: space-around;
}

.Ripple.is-reppling {
    animation: none;
}

@media screen and (max-width: 768px){
    #titles{
        font-size: 1.5rem;
    }
    a {
        display: block;
        padding: 0rem 0rem;
    }
    #discountTitleName{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 367px){
    #discountTitle{
        border-left: 2px solid white;
        border-radius: 0rem;
    }
}

@media screen and (max-width: 364px){
    #discountTitle{
        border-top: 2px solid white;
        border-radius: 0rem;
    }
    #discountTitleName{
        margin-top: 0.4rem;
        font-size: 1.5rem;
        border-top: none;
    }
}

@media screen and (max-width: 500px){
    #titlesImg{
       display: none;
    }
}

.tab-content {
    padding: 0rem;
    padding-top: 2rem;
}
