@media screen and (max-width: 900px) {
  #hide {
    display: none;
  }
}

/* @media screen and (min-width: 925px) {
  #ipad{
    display: none;
    visibility: hidden;
  }
  #mobile{
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 768px) and (max-width: 925px) {
  #web{
    display: none;
    visibility: hidden;
  }
  #mobile{
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 768px) {
  #web{
    display: none;
    visibility: hidden;
  }
  #ipad{
    display: none;
    visibility: hidden;
  }
} */

#searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

#snowboardTitle{
  color: white;
}

#mtbTitle{
  color: black;
}

#backgroundMTB {
  background-image: url('/Backgrounds/mountain-biking.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundMTBLogin {
  background-image: url('/Backgrounds/mountain-biking.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundMTBSideNav {
  background-color: black;     
}

@media screen and (max-width: 768px) {
  #backgroundMTB {
    background-image: url('/Backgrounds/mountain-bike-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #backgroundMTBLogin {
    background-image: url('/Backgrounds/mountain-bike-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center center;
    height: 100%;  
  }
  #mtbTitle{
    color: black
  }
}

#backgroundSnowboardLogin {
  background-image: url('/Backgrounds/snowboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundSnowboardMobile {
  background-image: url('/Backgrounds/snowboard-sidebar.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSnowboardSideNav {
  background-image: url('/Backgrounds/snowboard-sidebar.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundSnowboard {
    background-image: url('/Backgrounds/snowboard-sidebar.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
}

#backgroundSnowboard {
  background-image: url('/Backgrounds/snowboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSurf {
  background-image: url('/Backgrounds/surf-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSurfLogin {
  background-image: url('/Backgrounds/surf-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;
}

#surfTitle{
  color:white
}

#backgroundSurfMobile {
  background-image: url('/Backgrounds/surf_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSurfSideNav {
  background-image: url('/Backgrounds/surf_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}



@media screen and (max-width: 768px) {
  #backgroundSurf {
    background-image: url('/Backgrounds/surf_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #backgroundSurfLogin {
    background-image: url('/Backgrounds/surf_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    height: 100%;    
  }
  #surfTitle{
    color: black
  }
}

#backgroundSkate {
  background-image: url('/Backgrounds/skateboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundSkateLogin {
  background-image: url('/Backgrounds/skateboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundSkateMobile {
  background-image: url('/Backgrounds/skateboard_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSkateSideNav {
  background-image: url('/Backgrounds/skateboard_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}

@media screen and (max-width: 768px) {
  #backgroundSkate {
    background-image: url('/Backgrounds/skateboard_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #skateTitle{
    color: white
  }
}

#bmxTitle{
  color: black;
}

#backgroundBMX {
  background-image: url('/Backgrounds/bmx_background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundBMXLogin {
  background-image: url('/Backgrounds/bmx_background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundBMXMobile {
  background-image: url('/Backgrounds/bmx_mobile2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundBMXSideNav {
  background-image: url('/Backgrounds/bmx_mobile2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundBMX {
    background-image: url('/Backgrounds/bmx_mobile2.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }

  #bmxTitle{
    color: white;
  }
}

#scooterTitle{
  color: white;
}

#backgroundScooter {
  background-image: url('/Backgrounds/scooter_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundScooterLogin {
  background-image: url('/Backgrounds/scooter_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundScooterMobile {
  background-image: url('/Backgrounds/scooter.jpeg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundScooterSideNav {
  background-image: url('/Backgrounds/scooter.jpeg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundScooter {
    background-image: url('/Backgrounds/scooter.jpeg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }

  #scooterTitle{
    color: white;
  }
}

#skiTitle{
  color: black;
}

#backgroundSki {
  background-image: url('/Backgrounds/skiing_background2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundSkiLogin {
  background-image: url('/Backgrounds/skiing_background2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundSkiMobile {
  background-image: url('/Backgrounds/ski_background_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSkiSideNav {
  background-image: url('/Backgrounds/ski_background_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundSki {
    background-image: url('/Backgrounds/ski_background_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }

  #skiTitle{
    color: white;
  }
}

#backgroundApparel {
  background-image: url('/Backgrounds/apparel.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundApparelLogin {
  background-image: url('/Backgrounds/apparel.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundApparelMobile {
  background-image: url('/Backgrounds/apparel_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundApparelSideNav {
  background-image: url('/Backgrounds/apparel_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}

#apparelTitle{
  color: black;
}


@media screen and (max-width: 768px) {
  #backgroundApparel {
    background-image: url('/Backgrounds/apparel_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #backgroundApparelLogin {
    background-image: url('/Backgrounds/apparel_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    height: 100%;    
  }
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h3 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h4 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h5 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 1px solid black;
}

.row{
  margin-left: 0px;
  margin-right: 0px;
}

.btn {
  padding: .84rem 1.5rem;
      padding-top: 0.84rem;
      padding-right: 1.5rem;
      padding-bottom: 0.84rem;
      padding-left: 1.5rem;
}

.classic-tabs .nav li a {
  display: block;
  padding: 10px 24px;
      padding-top: 10px;
      padding-right: 24px;
      padding-bottom: 10px;
      padding-left: 24px;
  font-size: 13px;
  color: rgba(255,255,255,0.7);
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
}

@media screen and (max-width: 767px) {
  #tabTitle{
    font-size: 18px;
  }
  #navIcon{
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
}

#searchInput::placeholder {
  color: white;
}

.fa-2x {
  font-size: 1.5em;
}


#btnSM {
  padding-top: .15rem;
  padding-bottom: .12rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 80px;
  height: min-content;
  font-family: 'Oswald';
  font-size: 11px;
}

#btnSMWhite {
  padding-top: .25rem;
  padding-bottom: .22rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 80px;
  height: min-content;
  font-family: 'Oswald';
  font-size: 11px;
}

