body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, sans-serif;
}

input {
  max-width: 500px;
}

.gray {
  color: #828282;
}

.orange {
  background-color: #ff6600;
}

.background-gray {
  background-color: rgb(246,246,239);
}

.f11 {
  font-size: 11px;
}

.w85 {
  width: 85%;
}

.button {
  font-family: monospace;
  font-size: 10pt;
  color: black;
  background-color: buttonface;
  text-align: center;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  cursor: pointer;
  max-width: 250px;
}

.dropdown-toggle:after {
  display: none;
 }

.black-skin .side-nav .collapsible li .collapsible-header.active{
  background: transparent !important;
  color: #f8f8ff;
}

.black-skin .side-nav .collapsible li .collapsible-header:hover {
  background: transparent !important;
  color: #f8f8ff;
}

.md-form #searchSideNav:-ms-input-placeholder {
  color: white
}

.md-form #searchSideNav::placeholder {
  color: white
}

a{
  display: block;
  padding: .5rem 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: -0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
}


.navbar.scrolling-navbar {
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background 0.5s ease-in-out,padding 0.5s ease-in-out;
}

.side-nav .fa-angle-down.rotate-icon{
  margin-top: 6px;
}

.side-nav .collapsible {
  padding: 0;
  margin: 0;
  margin-top: 0rem;
}

@media screen and (min-width:768px) and (max-width: 1340px){
  #hideNav{
    display: none;
  }
  #hideSocialLogout{
    display: none;
  }
}

@media screen and (min-width:768px){
  #logoName{
    display: none;
  }
}

@media screen and (max-width:768px){
  #logoName{
    margin-top: 2px;
  }
  #hideSocial{
    display: none;
  }
}

@media screen and (max-width:768px){
  .side-nav .fa-angle-down.rotate-icon {
    margin-top: -2px;
}
}


a {
  outline: none;
  border: none;
}

.black-skin .side-nav .collapsible li .collapsible-body a:hover, .black-skin .side-nav .collapsible li .collapsible-body a.active, .black-skin .side-nav .collapsible li .collapsible-body a:active {
  color: white;
}
.black-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .black-skin .side-nav .collapsible li a:not(.collapsible-header).active, .black-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: white !important;
}

.black-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
  font-weight: bolder;
}

.black-skin .side-nav .social {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

#toggleLines {
  padding-top: 11px;
}

@media screen and (max-width: 768px) {
  #toggleLines{
    padding-top: 5px;
  }
}

.md-form #searchSideNav[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid white;
  box-shadow: none;
}

.goog-te-gadget-simple {
  background-color: #000;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  font-size: 10pt;
  display: block ruby;
  padding-top: 1px;
  padding-bottom: 2px;
  cursor: pointer;
  zoom: 1;
  *display: inline;
}
 
.goog-te-gadget-simple .goog-te-menu-value span {
  text-decoration: none;
  color: white;
  font-size: revert;
  padding-right: 3px;
}

.goog-te-gadget img {
  display: none;
}

.goog-te-banner-frame {
  left: 0px;
  top: 0px;
  height: 39px;
  width: 100%;
  z-index: 0;
  position: fixed;
  border: none;
      border-bottom-color: currentcolor;
      border-bottom-style: none;
      border-bottom-width: medium;
  border-bottom: 1px solid #6b90da;
  margin: 0;
  box-shadow: 0 0 8px 1px #999999;
  _position: absolute;
}

#homelogo {
  padding: 0px;
  margin-top: 3px;
}

#homelogoLoggedOut {
  padding: 0px;
  margin-top: 0px;
}

.navbar.scrolling-navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: background 0.5s ease-in-out,padding 0.5s ease-in-out;
}

@media screen and (min-width:768px) and (max-width: 768px){
  #homelogo {
    padding: 0px;
    margin-top: 0px;
  }
}

.side-nav .fa-angle-down.rotate-icon {
  position: absolute;
  top: .8rem;
  right: 0;
}

.side-nav .logo-wrapper img {
  padding-top: 10px;
  padding-bottom: 10px;
}

.side-nav .logo-wrapper a {
  height: 100px;
}

.side-nav .logo-wrapper {
  height: 100px;
}

.black-skin .navbar {
  color: #f8f8ff;
  background-color: #000;
  min-height: 2.5rem;
}

.black-skin .side-nav {
  background-color: #000;
}

.goog-te-gadget-simple {
  background-color: #121212;
}

.navbar {
  box-shadow: none
}

.navbar-nav {
  display: flex;
  flex-direction: unset;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media screen and (max-width: 768px){
  #navIcon {
    display: block;
    padding: 0rem 0rem;
    padding-left: 1rem;
  }
}

.side-nav .collapsible button {
  display: block;
  height: auto;
  font-size: 0.8rem;
  font-weight: 300;
  color: #fff;
}

.scrollbar-container {
  position: relative;
  height: 100%;
  min-height: 100vh;
}


@media screen and (max-width: 900px) {
  #hide {
    display: none;
  }
}

/* @media screen and (min-width: 925px) {
  #ipad{
    display: none;
    visibility: hidden;
  }
  #mobile{
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 768px) and (max-width: 925px) {
  #web{
    display: none;
    visibility: hidden;
  }
  #mobile{
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 768px) {
  #web{
    display: none;
    visibility: hidden;
  }
  #ipad{
    display: none;
    visibility: hidden;
  }
} */

#searchInput:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

#searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

#snowboardTitle{
  color: white;
}

#mtbTitle{
  color: black;
}

#backgroundMTB {
  background-image: url('/Backgrounds/mountain-biking.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundMTBLogin {
  background-image: url('/Backgrounds/mountain-biking.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundMTBSideNav {
  background-color: black;     
}

@media screen and (max-width: 768px) {
  #backgroundMTB {
    background-image: url('/Backgrounds/mountain-bike-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #backgroundMTBLogin {
    background-image: url('/Backgrounds/mountain-bike-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center center;
    height: 100%;  
  }
  #mtbTitle{
    color: black
  }
}

#backgroundSnowboardLogin {
  background-image: url('/Backgrounds/snowboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundSnowboardMobile {
  background-image: url('/Backgrounds/snowboard-sidebar.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSnowboardSideNav {
  background-image: url('/Backgrounds/snowboard-sidebar.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundSnowboard {
    background-image: url('/Backgrounds/snowboard-sidebar.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
}

#backgroundSnowboard {
  background-image: url('/Backgrounds/snowboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSurf {
  background-image: url('/Backgrounds/surf-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSurfLogin {
  background-image: url('/Backgrounds/surf-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;
}

#surfTitle{
  color:white
}

#backgroundSurfMobile {
  background-image: url('/Backgrounds/surf_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSurfSideNav {
  background-image: url('/Backgrounds/surf_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}



@media screen and (max-width: 768px) {
  #backgroundSurf {
    background-image: url('/Backgrounds/surf_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #backgroundSurfLogin {
    background-image: url('/Backgrounds/surf_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    height: 100%;    
  }
  #surfTitle{
    color: black
  }
}

#backgroundSkate {
  background-image: url('/Backgrounds/skateboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundSkateLogin {
  background-image: url('/Backgrounds/skateboarding-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundSkateMobile {
  background-image: url('/Backgrounds/skateboard_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSkateSideNav {
  background-image: url('/Backgrounds/skateboard_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}

@media screen and (max-width: 768px) {
  #backgroundSkate {
    background-image: url('/Backgrounds/skateboard_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #skateTitle{
    color: white
  }
}

#bmxTitle{
  color: black;
}

#backgroundBMX {
  background-image: url('/Backgrounds/bmx_background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundBMXLogin {
  background-image: url('/Backgrounds/bmx_background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundBMXMobile {
  background-image: url('/Backgrounds/bmx_mobile2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundBMXSideNav {
  background-image: url('/Backgrounds/bmx_mobile2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundBMX {
    background-image: url('/Backgrounds/bmx_mobile2.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }

  #bmxTitle{
    color: white;
  }
}

#scooterTitle{
  color: white;
}

#backgroundScooter {
  background-image: url('/Backgrounds/scooter_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundScooterLogin {
  background-image: url('/Backgrounds/scooter_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundScooterMobile {
  background-image: url('/Backgrounds/scooter.jpeg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundScooterSideNav {
  background-image: url('/Backgrounds/scooter.jpeg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundScooter {
    background-image: url('/Backgrounds/scooter.jpeg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }

  #scooterTitle{
    color: white;
  }
}

#skiTitle{
  color: black;
}

#backgroundSki {
  background-image: url('/Backgrounds/skiing_background2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundSkiLogin {
  background-image: url('/Backgrounds/skiing_background2.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundSkiMobile {
  background-image: url('/Backgrounds/ski_background_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundSkiSideNav {
  background-image: url('/Backgrounds/ski_background_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}


@media screen and (max-width: 768px) {
  #backgroundSki {
    background-image: url('/Backgrounds/ski_background_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }

  #skiTitle{
    color: white;
  }
}

#backgroundApparel {
  background-image: url('/Backgrounds/apparel.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;  
}

#backgroundApparelLogin {
  background-image: url('/Backgrounds/apparel.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center center;
  height: 100%;  
}

#backgroundApparelMobile {
  background-image: url('/Backgrounds/apparel_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100%;    
}

#backgroundApparelSideNav {
  background-image: url('/Backgrounds/apparel_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center top;
  min-height: 110vh;
  height: 100%;    
}

#apparelTitle{
  color: black;
}


@media screen and (max-width: 768px) {
  #backgroundApparel {
    background-image: url('/Backgrounds/apparel_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    background-attachment: fixed;
    min-height: 100vh;
    height: 100%;    
  }
  #backgroundApparelLogin {
    background-image: url('/Backgrounds/apparel_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center top;
    height: 100%;    
  }
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h3 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h4 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h5 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 1px solid black;
}

.row{
  margin-left: 0px;
  margin-right: 0px;
}

.btn {
  padding: .84rem 1.5rem;
      padding-top: 0.84rem;
      padding-right: 1.5rem;
      padding-bottom: 0.84rem;
      padding-left: 1.5rem;
}

.classic-tabs .nav li a {
  display: block;
  padding: 10px 24px;
      padding-top: 10px;
      padding-right: 24px;
      padding-bottom: 10px;
      padding-left: 24px;
  font-size: 13px;
  color: rgba(255,255,255,0.7);
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
}

@media screen and (max-width: 767px) {
  #tabTitle{
    font-size: 18px;
  }
  #navIcon{
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
}

#searchInput:-ms-input-placeholder {
  color: white;
}

#searchInput::placeholder {
  color: white;
}

.fa-2x {
  font-size: 1.5em;
}


#btnSM {
  padding-top: .15rem;
  padding-bottom: .12rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 80px;
  height: -webkit-min-content;
  height: min-content;
  font-family: 'Oswald';
  font-size: 11px;
}

#btnSMWhite {
  padding-top: .25rem;
  padding-bottom: .22rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 80px;
  height: -webkit-min-content;
  height: min-content;
  font-family: 'Oswald';
  font-size: 11px;
}


#video {
  width: -webkit-fill-available;
  height: auto;
  max-height: 300px;
}

#sponsoredVideo {
  width: -webkit-fill-available;
  height: 240px;
}

#image {
  max-height: 300px;
  max-width: -webkit-fill-available;
}

#videoFrame{
  width: 430px;
  justify-content: center;
  display: inline-block;
}

.col {
  flex-basis: 0;
  flex-grow: 0;
}

@media screen and (max-width: 500px) {
  #sponsoredVideo{
    width: -webkit-fill-available;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
#videoFrame{
  width: 350px;
  justify-content: center;
  display: inline-block;
}
}

@media screen and (max-width: 350px) {
  #sponsoredVideo{
    width: 300px;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
#videoFrame{
  width: 300px;
  justify-content: center;
  display: inline-block;
}
}

#editDropdown {
  margin: 0rem;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  padding: 0rem;
  font-size: .81rem;
  box-shadow: none;
  border-radius: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
      margin-top: 0.125rem;
      margin-bottom: 0px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: .25rem;
  left: -50px;
}


.dropdown-menu {
  font-size: 1rem;
  color: #212529;
  text-align: center;
  list-style: none;
}

.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:active, .dropup .dropdown-menu .dropdown-item:hover, .dropup .dropdown-menu .dropdown-item:active, .dropleft .dropdown-menu .dropdown-item:hover, .dropleft .dropdown-menu .dropdown-item:active, .dropright .dropdown-menu .dropdown-item:hover, .dropright .dropdown-menu .dropdown-item:active {
  background-color: transparent;
  border-radius: .125rem;
  box-shadow: none;
  transition: all 0.1s linear;
  left: -80px;
}

.dropdown .dropdown-menu .dropdown-item, .dropup .dropdown-menu .dropdown-item, .dropleft .dropdown-menu .dropdown-item, .dropright .dropdown-menu .dropdown-item {
  padding: 0rem;
  margin-left: 0;
  font-size: .9rem;
}

.btn-default {
  color: #fff;
  background-color: #000000 !important;
}


@media screen and (min-width: 768px){
  #deleteButton{
    display: none;
  }
  #editButton{
    display: none;
  }
}

#deleteButton{
  padding-top: .20rem;
  padding-bottom: .17rem;
}
#editButton{
  padding-top: .20rem;
  padding-bottom: .17rem;
}

@media screen and (max-width: 768px) {
  #dropupmenu{
    display: none;
  }
}

@media screen and (min-width:990px) and (max-width: 1050px){
  #sponsoredVideo{
    width: 400px;
    height: auto;
  }
}

  .dropdown, .dropleft, .dropright, .dropup {
    position: relative;
    text-align: end;
    margin-bottom: -20px;
    z-index: 10;
}

.col {
  flex-basis: 0;
  flex-grow: 0;
  max-width: 100%;
}

#tileWidth{
  min-width: 440px;
}

@media screen and (max-width: 768px) {
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 90%;
  }
  #tileWidth{
    min-width: 440px;
  }
  #normal{
    display: none;
  }
  .carousel-fade .carousel-item-next, .carousel-fade .carousel-item-prev, .carousel-fade .carousel-item.active, .carousel-fade .carousel-item-left.active, .carousel-fade .carousel-item-prev.active {
    transform: translate3d(0, 0, 0);
    text-align: -moz-center
  }
  #dropupmenu{
    display: none;
  }
  #hideWebsite{
    display: none;
  }
  #hideCode{
    display: none;
  }
}

@media screen and (max-width: 500px) {
  #tileWidth{
    min-width: 355px;
    max-width: 355px;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
}

@media screen and (max-width: 350px) {
  #tileWidth{
    min-width: 300px;
    max-width: 300px;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
#products{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
#codeButton{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
#websiteButton{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
}


@media screen and (min-width: 769px){
  #carouselDiscounts{
    display: none;
  }
  #editDropdownDiscount{
    margin: 0rem;
    color: inherit;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: .125rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    padding: 0rem;
    margin-left: 0.8rem;
    font-size: .81rem;
  }
  #threedots{
    left: -65px;
    position: relative;
    text-align: center;
  }
  #websiteButton{
    display: none;
  }
  #codeButton{
    display: none;
  }
  #deleteButton{
    display: none;
  }
  #editButton{
    display: none;
  }
}


@media screen and (min-width: 715px) {
  .col {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.carousel-caption {
  position: static;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.modal-dialog .modal-content {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
}

.modal-header {
  display: block;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.close {
  display: none;
}

carousel-control-next-icon carousel-control-prev-icon {
  background: black;
}

#dropMenuDiscount {
  left: -65px; 
  position: relative;
}

#dropDownIcon {
  margin-top: 10px;
  margin-left: 15px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
      margin-top: 0.125rem;
      margin-bottom: 0px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: .25rem;
  left: -50px;
}

.carousel .carousel-slide-item {
  transition: left 0.7s;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
}




.md-form label {
  color: black;
  font-weight: bolder;
}

.md-form label.active {
  color: black;
  font-weight: bolder;
}

input {
  max-width: none;
  color: black;
}

select {
  width: 100%;
  background-color: transparent;
  border: transparent;
  color: black;
  border-bottom: 1px solid black;
}

select * {
  background-color: transparent;
  color: black;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0rem;
}

label {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

input.active {
  color: black;
}

label.active {
  color: black
}

.md-form input:not([type]):focus:not([readonly]) + label, 
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="search-md"]:focus:not([readonly]) + label, 
.md-form textarea.md-textarea:focus:not([readonly]) + label {
  color:black;
}

.md-form input:not([type]):focus:not([readonly]), 
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="search-md"]:focus:not([readonly]), 
.md-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid black;
  box-shadow: none;
}

.custom-select {
  color:black
}

.md-form {
  margin-bottom: 2rem;
  margin-right: 0rem;
}

.popover-body {
  color: black;
  border: 1px solid rgb(0, 0, 0, 0.4);
}

.card .md-form label {
  font-weight: 400;
}

.md-form label.active {
  color: black;
  font-weight: bolder;
}

.popover .popover_arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 6px 6px 6px 252px;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
      margin-left: 252px;
  color: transparent;
}

.btn{
  box-shadow: none;
}

.dropdown-content li > a, .dropdown-content li > span {
  display: block;
  padding: .5rem;
  font-size: .9rem;
  color: black;
}

.select-wrapper > label.mdb-main-label {
  position: absolute;
  top: 0rem;
  left: 0;
  font-weight: 400;
  color: black;
  transition: .2s ease-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.text-primary {
  color: black !important;
  font-weight: bolder;
}

.select-wrapper > label.mdb-main-label.active {
  top: 0;
  left: 0;
  font-size: .8rem;
  transform: translateY(-14px);
  font-weight: bolder;
}

.form-check-input[type="checkbox"] + label, label.btn input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  height: 1.5625rem;
  padding-left: 35px;
  line-height: 1.5625rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}

.dropdown-content {
  max-height: 25rem;
  background-color: #000;
}

.form-check-input[type="checkbox"] + label, label.btn input[type="checkbox"] + label {
  margin-bottom: .5rem;
  color: #f8f8ff;
  font-family: 'Oswald';
}

.select-wrapper:not(.md-outline) .select-dropdown:focus {
  border-bottom: 1px solid #f8f8ff;
  box-shadow: 0 1px 0 0 #f8f8ff;
}

.dropdown-content li > a, .dropdown-content li > span {
  display: block;
  padding: 0.5rem;
  font-size: .9rem;
  color: #f8f8ff;
}

.select-dropdown li.disabled, .select-dropdown li.disabled>span, .select-dropdown li.optgroup {
  color: #f8f8ff;
  cursor: context-menu;
  background-color: transparent !important;
}

.dropdown-content li:hover, .dropdown-content li.active {
  background-color: transparent;
}

.dropdown-content li {
  width: 100%;
  clear: both;
  line-height: 1.3rem;
  color: #f8f8ff;
  text-align: left;
  text-transform: none;
  cursor: pointer;
}

.select-wrapper span.caret {
  position: absolute;
  top: 0.8rem;
  right: 0;
  font-size: .63rem;
  color: transparent;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f8f8ff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fadeElement.fadeIn {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  max-height: 250px;
}

.text-primary {
  color: #f8f8ff !important;
  font-weight: bolder;
}

.select-wrapper > label.mdb-main-label {
  position: absolute;
  top: 0rem;
  left: 0;
  font-weight: 400;
  color: #f8f8ff;
  transition: .2s ease-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.select-dropdown {
  color: transparent;
}

.form-control:focus {
  color: #f8f8ff;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#selectSearchInput {
  margin-top: -15px;
  margin-bottom: -1.5rem;
}

.file-field span {
  cursor: pointer;
  color: #f8f8ff;
  font-family: 'Oswald';
  font-size: 14px;
}

.md-form input:not([type]).valid, .md-form input:not([type]):focus.valid, .md-form input[type="text"]:not(.browser-default).valid, .md-form input[type="text"]:not(.browser-default):focus.valid, .md-form input[type="password"]:not(.browser-default).valid, .md-form input[type="password"]:not(.browser-default):focus.valid, .md-form input[type="email"]:not(.browser-default).valid, .md-form input[type="email"]:not(.browser-default):focus.valid, .md-form input[type="url"]:not(.browser-default).valid, .md-form input[type="url"]:not(.browser-default):focus.valid, .md-form input[type="time"]:not(.browser-default).valid, .md-form input[type="time"]:not(.browser-default):focus.valid, .md-form input[type="date"]:not(.browser-default).valid, .md-form input[type="date"]:not(.browser-default):focus.valid, .md-form input[type="datetime"]:not(.browser-default).valid, .md-form input[type="datetime"]:not(.browser-default):focus.valid, .md-form input[type="datetime-local"]:not(.browser-default).valid, .md-form input[type="datetime-local"]:not(.browser-default):focus.valid, .md-form input[type="tel"]:not(.browser-default).valid, .md-form input[type="tel"]:not(.browser-default):focus.valid, .md-form input[type="number"]:not(.browser-default).valid, .md-form input[type="number"]:not(.browser-default):focus.valid, .md-form input[type="search"]:not(.browser-default).valid, .md-form input[type="search"]:not(.browser-default):focus.valid, .md-form input[type="phone"]:not(.browser-default).valid, .md-form input[type="phone"]:not(.browser-default):focus.valid, .md-form input[type="search-md"].valid, .md-form input[type="search-md"]:focus.valid, .md-form textarea.md-textarea.valid, .md-form textarea.md-textarea:focus.valid {
  border-bottom: 1px solid #f8f8ff;
  box-shadow: 0 1px 0 0 #f8f8ff;
}

.btn-black.btn-sm {
  border: 1px solid #f8f8ff;
  border-radius: 1.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
}

.file-path.validate {
  color: #f8f8ff !important;
  font-family: 'Oswald';
}

.file-path.validate.valid {
  color: #f8f8ff !important;
  font-family: 'Oswald';
}

.select-dropdown {
  color: #f8f8ff;
  font-family: 'Oswald';
}

.btn-red {
  color: #fff;
  background-color: #c30707 !important;
}

.md-form label {
    color: #f8f8ff;
    font-family: 'Oswald';
}

.md-form input:not([type]):focus:not([readonly]) + label, .md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="password"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="email"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="number"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="search"]:not(.browser-default):focus:not([readonly]) + label, .md-form input[type="search-md"]:focus:not([readonly]) + label, .md-form textarea.md-textarea:focus:not([readonly]) + label {
    color: #f8f8ff;
    font-family: 'Oswald';
}

.md-form label.active {
    color: #f8f8ff;
    font-family: 'Oswald';
}

.md-form .form-control#input {
    border-bottom: 1px solid #f8f8ff;
}

.btn[class*="btn-outline-"] {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.md-form input:not([type]), .md-form input[type="text"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="search"]:not(.browser-default), .md-form input[type="phone"]:not(.browser-default), .md-form input[type="search-md"], .md-form textarea.md-textarea {
    box-sizing: content-box;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #f8f8ff;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #f8f8ff;
    box-shadow: none;
}

.form-check-input[type="checkbox"]:checked+label:before, label.btn input[type="checkbox"]:checked+label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 1.375rem;
    border-top: 2px solid transparent;
    border-right: 2px solid #f8f8ff;
    border-bottom: 2px solid #f8f8ff;
    border-left: 2px solid transparent;
    transform: rotate(
40deg
);
    transform-origin: 100% 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
@media screen and (max-width: 576px){
    .classic-tabs .nav li {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
    }
  }
.legal-title {
    text-align: center;
    font-family: 'Bebas Neue';
    color: #121212;
}

.legal-subheading {
    font-family: 'Bebas Neue';
    color: #121212;
    padding-top: 1rem;
}

.legal-text {
    font-family: 'Roboto';
    color: #121212;
    font-weight: 400;
    display: inline;
    text-align: justify;
}

.legal-text-bolder {
    font-family: 'Roboto';
    color: #121212;
    font-weight: bolder;
    display: inline;
}

.legal-link {
    display: inline;
    padding: 0;
    word-wrap: break-word;
}

table td {
    font-size: .9rem;
    font-weight: 300;
    padding-right: 10px;
}

table {
    border-collapse: collapse;
  }
  
  tr {
    border-bottom: 1px solid black;
  }
.md-tabs {
    position: relative;
    z-index: 1;
    padding: .7rem;
    margin-right: 0rem;
    margin-bottom: -20px;
    margin-left: 0rem;
    background-color: #2bbbad;
    border: 0;
    border-radius: .25rem;
}

#cardProfile {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

#cardProfile {
    font-weight: 400;
    border: 0;
    box-shadow: none;
}

.tabs-black {
    background-color: rgb(0, 0, 0, 0.8) !important;
}

.md-tabs .nav-link.active, .md-tabs .nav-item.open .nav-link {
    color: #fff;
    border-radius: .25rem;
    transition: all 1s;
    background-color: transparent;
}

.font-weight-bolder {
    font-weight: 400 !important;
}

#profileButtons{
    justify-content: space-around;
}

.Ripple.is-reppling {
    animation: none;
}

@media screen and (max-width: 768px){
    #titles{
        font-size: 1.5rem;
    }
    a {
        display: block;
        padding: 0rem 0rem;
    }
    #discountTitleName{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 367px){
    #discountTitle{
        border-left: 2px solid white;
        border-radius: 0rem;
    }
}

@media screen and (max-width: 364px){
    #discountTitle{
        border-top: 2px solid white;
        border-radius: 0rem;
    }
    #discountTitleName{
        margin-top: 0.4rem;
        font-size: 1.5rem;
        border-top: none;
    }
}

@media screen and (max-width: 500px){
    #titlesImg{
       display: none;
    }
}

.tab-content {
    padding: 0rem;
    padding-top: 2rem;
}

