.legal-title {
    text-align: center;
    font-family: 'Bebas Neue';
    color: #121212;
}

.legal-subheading {
    font-family: 'Bebas Neue';
    color: #121212;
    padding-top: 1rem;
}

.legal-text {
    font-family: 'Roboto';
    color: #121212;
    font-weight: 400;
    display: inline;
    text-align: justify;
}

.legal-text-bolder {
    font-family: 'Roboto';
    color: #121212;
    font-weight: bolder;
    display: inline;
}

.legal-link {
    display: inline;
    padding: 0;
    word-wrap: break-word;
}

table td {
    font-size: .9rem;
    font-weight: 300;
    padding-right: 10px;
}

table {
    border-collapse: collapse;
  }
  
  tr {
    border-bottom: 1px solid black;
  }