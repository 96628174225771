
.md-form label {
  color: black;
  font-weight: bolder;
}

.md-form label.active {
  color: black;
  font-weight: bolder;
}

input {
  max-width: none;
  color: black;
}

select {
  width: 100%;
  background-color: transparent;
  border: transparent;
  color: black;
  border-bottom: 1px solid black;
}

select * {
  background-color: transparent;
  color: black;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0rem;
}

label {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

input.active {
  color: black;
}

label.active {
  color: black
}

.md-form input:not([type]):focus:not([readonly]) + label, 
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]) + label, 
.md-form input[type="search-md"]:focus:not([readonly]) + label, 
.md-form textarea.md-textarea:focus:not([readonly]) + label {
  color:black;
}

.md-form input:not([type]):focus:not([readonly]), 
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="search-md"]:focus:not([readonly]), 
.md-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid black;
  box-shadow: none;
}

.custom-select {
  color:black
}

.md-form {
  margin-bottom: 2rem;
  margin-right: 0rem;
}

.popover-body {
  color: black;
  border: 1px solid rgb(0, 0, 0, 0.4);
}

.card .md-form label {
  font-weight: 400;
}

.md-form label.active {
  color: black;
  font-weight: bolder;
}

.popover .popover_arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 6px 6px 6px 252px;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
      margin-left: 252px;
  color: transparent;
}

.btn{
  box-shadow: none;
}

.dropdown-content li > a, .dropdown-content li > span {
  display: block;
  padding: .5rem;
  font-size: .9rem;
  color: black;
}

.select-wrapper > label.mdb-main-label {
  position: absolute;
  top: 0rem;
  left: 0;
  font-weight: 400;
  color: black;
  transition: .2s ease-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.text-primary {
  color: black !important;
  font-weight: bolder;
}

.select-wrapper > label.mdb-main-label.active {
  top: 0;
  left: 0;
  font-size: .8rem;
  transform: translateY(-14px);
  font-weight: bolder;
}

.form-check-input[type="checkbox"] + label, label.btn input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  height: 1.5625rem;
  padding-left: 35px;
  line-height: 1.5625rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}

.dropdown-content {
  max-height: 25rem;
  background-color: #000;
}

.form-check-input[type="checkbox"] + label, label.btn input[type="checkbox"] + label {
  margin-bottom: .5rem;
  color: #f8f8ff;
  font-family: 'Oswald';
}

.select-wrapper:not(.md-outline) .select-dropdown:focus {
  border-bottom: 1px solid #f8f8ff;
  box-shadow: 0 1px 0 0 #f8f8ff;
}

.dropdown-content li > a, .dropdown-content li > span {
  display: block;
  padding: 0.5rem;
  font-size: .9rem;
  color: #f8f8ff;
}

.select-dropdown li.disabled, .select-dropdown li.disabled>span, .select-dropdown li.optgroup {
  color: #f8f8ff;
  cursor: context-menu;
  background-color: transparent !important;
}

.dropdown-content li:hover, .dropdown-content li.active {
  background-color: transparent;
}

.dropdown-content li {
  width: 100%;
  clear: both;
  line-height: 1.3rem;
  color: #f8f8ff;
  text-align: left;
  text-transform: none;
  cursor: pointer;
}

.select-wrapper span.caret {
  position: absolute;
  top: 0.8rem;
  right: 0;
  font-size: .63rem;
  color: transparent;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f8f8ff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fadeElement.fadeIn {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  max-height: 250px;
}

.text-primary {
  color: #f8f8ff !important;
  font-weight: bolder;
}

.select-wrapper > label.mdb-main-label {
  position: absolute;
  top: 0rem;
  left: 0;
  font-weight: 400;
  color: #f8f8ff;
  transition: .2s ease-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.select-dropdown {
  color: transparent;
}

.form-control:focus {
  color: #f8f8ff;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#selectSearchInput {
  margin-top: -15px;
  margin-bottom: -1.5rem;
}

.file-field span {
  cursor: pointer;
  color: #f8f8ff;
  font-family: 'Oswald';
  font-size: 14px;
}

.md-form input:not([type]).valid, .md-form input:not([type]):focus.valid, .md-form input[type="text"]:not(.browser-default).valid, .md-form input[type="text"]:not(.browser-default):focus.valid, .md-form input[type="password"]:not(.browser-default).valid, .md-form input[type="password"]:not(.browser-default):focus.valid, .md-form input[type="email"]:not(.browser-default).valid, .md-form input[type="email"]:not(.browser-default):focus.valid, .md-form input[type="url"]:not(.browser-default).valid, .md-form input[type="url"]:not(.browser-default):focus.valid, .md-form input[type="time"]:not(.browser-default).valid, .md-form input[type="time"]:not(.browser-default):focus.valid, .md-form input[type="date"]:not(.browser-default).valid, .md-form input[type="date"]:not(.browser-default):focus.valid, .md-form input[type="datetime"]:not(.browser-default).valid, .md-form input[type="datetime"]:not(.browser-default):focus.valid, .md-form input[type="datetime-local"]:not(.browser-default).valid, .md-form input[type="datetime-local"]:not(.browser-default):focus.valid, .md-form input[type="tel"]:not(.browser-default).valid, .md-form input[type="tel"]:not(.browser-default):focus.valid, .md-form input[type="number"]:not(.browser-default).valid, .md-form input[type="number"]:not(.browser-default):focus.valid, .md-form input[type="search"]:not(.browser-default).valid, .md-form input[type="search"]:not(.browser-default):focus.valid, .md-form input[type="phone"]:not(.browser-default).valid, .md-form input[type="phone"]:not(.browser-default):focus.valid, .md-form input[type="search-md"].valid, .md-form input[type="search-md"]:focus.valid, .md-form textarea.md-textarea.valid, .md-form textarea.md-textarea:focus.valid {
  border-bottom: 1px solid #f8f8ff;
  box-shadow: 0 1px 0 0 #f8f8ff;
}

.btn-black.btn-sm {
  border: 1px solid #f8f8ff;
  border-radius: 1.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
}

.file-path.validate {
  color: #f8f8ff !important;
  font-family: 'Oswald';
}

.file-path.validate.valid {
  color: #f8f8ff !important;
  font-family: 'Oswald';
}

.select-dropdown {
  color: #f8f8ff;
  font-family: 'Oswald';
}

.btn-red {
  color: #fff;
  background-color: #c30707 !important;
}
