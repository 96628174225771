.col {
  flex-basis: 0;
  flex-grow: 0;
  max-width: 100%;
}

#tileWidth{
  min-width: 440px;
}

@media screen and (max-width: 768px) {
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 90%;
  }
  #tileWidth{
    min-width: 440px;
  }
  #normal{
    display: none;
  }
  .carousel-fade .carousel-item-next, .carousel-fade .carousel-item-prev, .carousel-fade .carousel-item.active, .carousel-fade .carousel-item-left.active, .carousel-fade .carousel-item-prev.active {
    transform: translate3d(0, 0, 0);
    text-align: -moz-center
  }
  #dropupmenu{
    display: none;
  }
  #hideWebsite{
    display: none;
  }
  #hideCode{
    display: none;
  }
}

@media screen and (max-width: 500px) {
  #tileWidth{
    min-width: 355px;
    max-width: 355px;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
}

@media screen and (max-width: 350px) {
  #tileWidth{
    min-width: 300px;
    max-width: 300px;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
#products{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
#codeButton{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
#websiteButton{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
}


@media screen and (min-width: 769px){
  #carouselDiscounts{
    display: none;
  }
  #editDropdownDiscount{
    margin: 0rem;
    color: inherit;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: .125rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    padding: 0rem;
    margin-left: 0.8rem;
    font-size: .81rem;
  }
  #threedots{
    left: -65px;
    position: relative;
    text-align: center;
  }
  #websiteButton{
    display: none;
  }
  #codeButton{
    display: none;
  }
  #deleteButton{
    display: none;
  }
  #editButton{
    display: none;
  }
}


@media screen and (min-width: 715px) {
  .col {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.carousel-caption {
  position: static;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.modal-dialog .modal-content {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
}

.modal-header {
  display: block;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.close {
  display: none;
}

carousel-control-next-icon carousel-control-prev-icon {
  background: black;
}

#dropMenuDiscount {
  left: -65px; 
  position: relative;
}

#dropDownIcon {
  margin-top: 10px;
  margin-left: 15px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
      margin-top: 0.125rem;
      margin-bottom: 0px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: .25rem;
  left: -50px;
}

.carousel .carousel-slide-item {
  transition: left 0.7s;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
}


