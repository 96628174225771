.dropdown-toggle:after {
  display: none;
 }

.black-skin .side-nav .collapsible li .collapsible-header.active{
  background: transparent !important;
  color: #f8f8ff;
}

.black-skin .side-nav .collapsible li .collapsible-header:hover {
  background: transparent !important;
  color: #f8f8ff;
}

.md-form #searchSideNav::placeholder {
  color: white
}

a{
  display: block;
  padding: .5rem 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: -0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
}


.navbar.scrolling-navbar {
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background 0.5s ease-in-out,padding 0.5s ease-in-out;
}

.side-nav .fa-angle-down.rotate-icon{
  margin-top: 6px;
}

.side-nav .collapsible {
  padding: 0;
  margin: 0;
  margin-top: 0rem;
}

@media screen and (min-width:768px) and (max-width: 1340px){
  #hideNav{
    display: none;
  }
  #hideSocialLogout{
    display: none;
  }
}

@media screen and (min-width:768px){
  #logoName{
    display: none;
  }
}

@media screen and (max-width:768px){
  #logoName{
    margin-top: 2px;
  }
  #hideSocial{
    display: none;
  }
}

@media screen and (max-width:768px){
  .side-nav .fa-angle-down.rotate-icon {
    margin-top: -2px;
}
}


a {
  outline: none;
  border: none;
}

.black-skin .side-nav .collapsible li .collapsible-body a:hover, .black-skin .side-nav .collapsible li .collapsible-body a.active, .black-skin .side-nav .collapsible li .collapsible-body a:active {
  color: white;
}
.black-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .black-skin .side-nav .collapsible li a:not(.collapsible-header).active, .black-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: white !important;
}

.black-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
  font-weight: bolder;
}

.black-skin .side-nav .social {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

#toggleLines {
  padding-top: 11px;
}

@media screen and (max-width: 768px) {
  #toggleLines{
    padding-top: 5px;
  }
}

.md-form #searchSideNav[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid white;
  box-shadow: none;
}

.goog-te-gadget-simple {
  background-color: #000;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  font-size: 10pt;
  display: block ruby;
  padding-top: 1px;
  padding-bottom: 2px;
  cursor: pointer;
  zoom: 1;
  *display: inline;
}
 
.goog-te-gadget-simple .goog-te-menu-value span {
  text-decoration: none;
  color: white;
  font-size: revert;
  padding-right: 3px;
}

.goog-te-gadget img {
  display: none;
}

.goog-te-banner-frame {
  left: 0px;
  top: 0px;
  height: 39px;
  width: 100%;
  z-index: 0;
  position: fixed;
  border: none;
      border-bottom-color: currentcolor;
      border-bottom-style: none;
      border-bottom-width: medium;
  border-bottom: 1px solid #6b90da;
  margin: 0;
  -moz-box-shadow: 0 0 8px 1px #999999;
  -webkit-box-shadow: 0 0 8px 1px #999999;
  box-shadow: 0 0 8px 1px #999999;
  _position: absolute;
}

#homelogo {
  padding: 0px;
  margin-top: 3px;
}

#homelogoLoggedOut {
  padding: 0px;
  margin-top: 0px;
}

.navbar.scrolling-navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: background 0.5s ease-in-out,padding 0.5s ease-in-out;
}

@media screen and (min-width:768px) and (max-width: 768px){
  #homelogo {
    padding: 0px;
    margin-top: 0px;
  }
}

.side-nav .fa-angle-down.rotate-icon {
  position: absolute;
  top: .8rem;
  right: 0;
}

.side-nav .logo-wrapper img {
  padding-top: 10px;
  padding-bottom: 10px;
}

.side-nav .logo-wrapper a {
  height: 100px;
}

.side-nav .logo-wrapper {
  height: 100px;
}

.black-skin .navbar {
  color: #f8f8ff;
  background-color: #000;
  min-height: 2.5rem;
}

.black-skin .side-nav {
  background-color: #000;
}

.goog-te-gadget-simple {
  background-color: #121212;
}

.navbar {
  box-shadow: none
}

.navbar-nav {
  display: flex;
  flex-direction: unset;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media screen and (max-width: 768px){
  #navIcon {
    display: block;
    padding: 0rem 0rem;
    padding-left: 1rem;
  }
}

.side-nav .collapsible button {
  display: block;
  height: auto;
  font-size: 0.8rem;
  font-weight: 300;
  color: #fff;
}

.scrollbar-container {
  position: relative;
  height: 100%;
  min-height: 100vh;
}

