#video {
  width: -webkit-fill-available;
  height: auto;
  max-height: 300px;
}

#sponsoredVideo {
  width: -webkit-fill-available;
  height: 240px;
}

#image {
  max-height: 300px;
  max-width: -webkit-fill-available;
}

#videoFrame{
  width: 430px;
  justify-content: center;
  display: inline-block;
}

.col {
  flex-basis: 0;
  flex-grow: 0;
}

@media screen and (max-width: 500px) {
  #sponsoredVideo{
    width: -webkit-fill-available;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
#videoFrame{
  width: 350px;
  justify-content: center;
  display: inline-block;
}
}

@media screen and (max-width: 350px) {
  #sponsoredVideo{
    width: 300px;
    height: auto;
  }
  .col {
    flex-basis: 0;
    flex-grow: 0;
    max-width: unset;
}
#videoFrame{
  width: 300px;
  justify-content: center;
  display: inline-block;
}
}

#editDropdown {
  margin: 0rem;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  padding: 0rem;
  font-size: .81rem;
  box-shadow: none;
  border-radius: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
      margin-top: 0.125rem;
      margin-bottom: 0px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: .25rem;
  left: -50px;
}


.dropdown-menu {
  font-size: 1rem;
  color: #212529;
  text-align: center;
  list-style: none;
}

.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:active, .dropup .dropdown-menu .dropdown-item:hover, .dropup .dropdown-menu .dropdown-item:active, .dropleft .dropdown-menu .dropdown-item:hover, .dropleft .dropdown-menu .dropdown-item:active, .dropright .dropdown-menu .dropdown-item:hover, .dropright .dropdown-menu .dropdown-item:active {
  background-color: transparent;
  border-radius: .125rem;
  box-shadow: none;
  transition: all 0.1s linear;
  left: -80px;
}

.dropdown .dropdown-menu .dropdown-item, .dropup .dropdown-menu .dropdown-item, .dropleft .dropdown-menu .dropdown-item, .dropright .dropdown-menu .dropdown-item {
  padding: 0rem;
  margin-left: 0;
  font-size: .9rem;
}

.btn-default {
  color: #fff;
  background-color: #000000 !important;
}


@media screen and (min-width: 768px){
  #deleteButton{
    display: none;
  }
  #editButton{
    display: none;
  }
}

#deleteButton{
  padding-top: .20rem;
  padding-bottom: .17rem;
}
#editButton{
  padding-top: .20rem;
  padding-bottom: .17rem;
}

@media screen and (max-width: 768px) {
  #dropupmenu{
    display: none;
  }
}

@media screen and (min-width:990px) and (max-width: 1050px){
  #sponsoredVideo{
    width: 400px;
    height: auto;
  }
}

  .dropdown, .dropleft, .dropright, .dropup {
    position: relative;
    text-align: end;
    margin-bottom: -20px;
    z-index: 10;
}
